export const careTaskOmittedReasons = [
  {
    id: 'ref',
    name: 'Refused'
  },
  {
    id: 'oof',
    name: 'Out of Facility'
  },
  {
    id: 'homeVisit',
    name: 'Home Visit'
  },
  {
    id: 'hol',
    name: 'Hold'
  },
  {
    id: 'oth',
    name: 'Other'
  }
] as const;

export const careTaskOmittedReasonIds = careTaskOmittedReasons.map(
  ({ id }) => id
);

export type CareTaskOmittedReasonId = (typeof careTaskOmittedReasonIds)[number];
