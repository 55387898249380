export const facilityTypes = [
  {
    id: 'rcfe',
    name: 'RCFE'
  },
  {
    id: 'arf',
    name: 'ARF'
  },
  {
    id: 'groupHome',
    name: 'Group Home'
  },
  {
    id: 'adp',
    name: 'Adult Day Program'
  },
  {
    id: 'srf',
    name: 'Social Rehabilitation Facility'
  },
  {
    id: 'ffh',
    name: 'Foster Family Home'
  },
  {
    id: 'sfh',
    name: 'Small Family Home'
  },
  {
    id: 'rcfci',
    name: 'Residential Care Facility for the Chronically Ill'
  }
] as const;

export const facilityTypeIds = facilityTypes.map(({ id }) => id);

export type FacilityTypeId = (typeof facilityTypeIds)[number];
