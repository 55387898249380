export const respirationDepth = [
  {
    id: 'shallow',
    name: 'Shallow'
  },
  {
    id: 'deep',
    name: 'Deep'
  },
  {
    id: 'normal',
    name: 'Normal'
  }
] as const;

export const respirationRhythm = [
  {
    id: 'regular',
    name: 'Regular'
  },
  {
    id: 'irregular',
    name: 'Irregular'
  }
] as const;

export const respirationDepthIds = respirationDepth.map(({ id }) => id);

export type RespirationDepthId = (typeof respirationDepthIds)[number];

export const respirationRhythmIds = respirationRhythm.map(({ id }) => id);

export type RespirationRhythmId = (typeof respirationRhythmIds)[number];
