import { keyBy, mapValues } from 'lodash';
import { activityTypes } from './activity-types.lookup';
import { allergyLevels } from './allergy-levels.lookup';
import { allergyTypes } from './allergy-types.lookup';
import { amountTypes } from './amount-types.lookup';
import { bmSizes } from './bm-sizes.lookup';
import { bmTextures } from './bm-textures.lookup';
import { bodyAbnormalities } from './body-abnormalities.lookup';
import { bodyParts } from './body-parts.lookup';
import { bodyPlanes } from './body-planes.lookup';
import { californiaRegionalCenters } from './california-regional-centers.lookup';
import { carePlanIssuers } from './care-plan-issuers.lookup';
import { careTaskFrequencyTypes } from './care-task-frequency-types.lookup';
import { careTaskOmittedReasons } from './care-task-omitted-reasons.lookup';
import { careTaskStates } from './care-task-states.lookup';
import { careTaskTypes } from './care-task-types.lookup';
import { continenceStatuses } from './continence-statuses.lookup';
import { countries } from './countries.lookup';
import { diagnosisLevels } from './diagnosis-levels.lookup';
import { dnrStatuses } from './dnr-statuses.lookup';
import { documentDeadlineIntervals } from './document-deadline-intervals.lookup';
import { documentLevels } from './document-levels.lookup';
import { documentParticipantRoles } from './document-participant-roles.lookup';
import { documentParticipantTypes } from './document-participant-types.lookup';
import { documentRenewalIntervals } from './document-renewal-intervals.lookup';
import { documentRequirements } from './document-requirements.lookup';
import { documentScheduleAnchors } from './document-schedule-anchors.lookup';
import { documentScopes } from './document-scopes.lookup';
import { documentStatuses } from './document-statuses.lookup';
import { documentTemplatePacketCreationRules } from './document-template-packet-creation-rules.lookup';
import { documentTypes } from './document-types.lookup';
import { doseTypes } from './dose-types.lookup';
import { employmentStatuses } from './employment-statuses.lookup';
import { eyeColors } from './eye-color.lookup';
import { facilityTypes } from './facility-types.lookup';
import { facilityUserStatuses } from './facility-user-statuses.lookup';
import { facilityUserTypes } from './facility-user-types.lookup';
import { featureFlags } from './feature-flags.lookup';
import { foodRequirements } from './food-requirements.lookup';
import { genders } from './genders.lookup';
import { heightUnits } from './height-units.lookup';
import { incidentReportNotificationRecipientTypes } from './incident-report-notification-recipient-types.lookup';
import { incidentReportStatuses } from './incident-report-statuses.lookup';
import { incidentTypes } from './incident-types.lookup';
import { listTypes } from './list-types';
import { logDetails } from './log-details.lookup';
import { mealTypes } from './meal-types.lookup';
import { measurements } from './measurements.lookup';
import { medicalEventTypes } from './medical-event-types.lookup';
import { medicalHistoryCategories } from './medical-history-categories.lookup';
import { medicalProfessionalTypes } from './medical-professional-types.lookup';
import { medicationDestructionItemStatuses } from './medication-destruction-item-statuses';
import { medicationDestructionItemReasons } from './medication-destruction-reasons.lookup';
import { medicationDestructionStatuses } from './medication-destruction-statuses.lookup';
import { medicationForms } from './medication-forms.lookup';
import { medicationInventoryTypes } from './medication-inventory-types.lookup';
import { medicationMarStates } from './medication-mar-states.lookup';
import { medicationOrderItemStatuses } from './medication-order-item-statuses.lookup';
import { medicationOrderStatuses } from './medication-order-statuses.lookup';
import { medicationRoutes } from './medication-routes.lookup';
import { medicationScheduleTypes } from './medication-schedule-types.lookup';
import { medicationStatuses } from './medication-statuses.lookup';
import { medicationTaskStates } from './medication-task-states.lookup';
import { medicationTypes } from './medication-types.lookup';
import { mobilityStatuses } from './mobility-statuses.lookup';
import { moods } from './moods.lookup';
import { omittedMedicationReasons } from './omitted-medication-reasons.lookup';
import { pdfReports } from './pdf-reports.lookup';
import { pharmacyApiMessageStatuses } from './pharmacy-api-message-statuses.lookup';
import { pharmacyApiMessageTypes } from './pharmacy-api-message-types.lookup';
import { pharmacyOrderingMethods } from './pharmacy-ordering-methods.lookup';
import { pharmacySoftwareVendors } from './pharmacy-software-vendors.lookup';
import { phoneTypes } from './phone-types.lookup';
import { positionSides } from './position-sides.lookup';
import { positions } from './positions.lookup';
import { prnResponses } from './prn-responses.lookup';
import { recordChangeActions } from './record-change-actions.lookup';
import { recordChangeStatuses } from './record-change-statuses.lookup';
import { residentContactRelationships } from './resident-contact-relationships.lookup';
import { residentStages } from './resident-stages.lookup';
import { residentStatuses } from './resident-statuses.lookup';
import { severityLevels } from './severity-levels.lookup';
import { sleepChecks } from './sleep-checks.lookup';
import { states } from './states.lookup';
import { temperatureUnits } from './temperature-units.lookup';
import { urineColors } from './urine-colors.lookup';
import { urineLogTypes } from './urine-log-types.lookup';
import { urineMeasurements } from './urine-measurements.lookup';
import { weightUnits } from './weight-units.lookup';
import { respirationDepth, respirationRhythm } from './respiration.lookup';

export const lookups = {
  activityTypes,
  allergyLevels,
  allergyTypes,
  amountTypes,
  bmSizes,
  bmTextures,
  bodyAbnormalities,
  bodyParts,
  bodyPlanes,
  californiaRegionalCenters,
  carePlanIssuers,
  careTaskFrequencyTypes,
  careTaskOmittedReasons,
  careTaskStates,
  careTaskTypes,
  continenceStatuses,
  countries,
  diagnosisLevels,
  dnrStatuses,
  documentDeadlineIntervals,
  documentLevels,
  documentParticipantRoles,
  documentParticipantTypes,
  documentRenewalIntervals,
  documentRequirements,
  documentScheduleAnchors,
  documentScopes,
  documentStatuses,
  documentTemplatePacketCreationRules,
  documentTypes,
  doseTypes,
  employmentStatuses,
  eyeColors,
  facilityTypes,
  facilityUserStatuses,
  facilityUserTypes,
  featureFlags,
  foodRequirements,
  genders,
  heightUnits,
  incidentReportNotificationRecipientTypes,
  incidentReportStatuses,
  incidentTypes,
  listTypes,
  logDetails,
  mealTypes,
  measurements,
  medicalEventTypes,
  medicalHistoryCategories,
  medicalProfessionalTypes,
  medicationDestructionItemReasons,
  medicationDestructionItemStatuses,
  medicationDestructionStatuses,
  medicationForms,
  medicationInventoryTypes,
  medicationMarStates,
  medicationOrderItemStatuses,
  medicationOrderStatuses,
  medicationRoutes,
  medicationScheduleTypes,
  medicationStatuses,
  medicationTaskStates,
  medicationTypes,
  mobilityStatuses,
  moods,
  omittedMedicationReasons,
  pdfReports,
  pharmacyApiMessageStatuses,
  pharmacyApiMessageTypes,
  pharmacySoftwareVendors,
  pharmacyOrderingMethods,
  phoneTypes,
  positions,
  positionSides,
  prnResponses,
  recordChangeStatuses,
  recordChangeTypes: recordChangeActions,
  residentContactRelationships,
  residentStages,
  residentStatuses,
  respirationDepth,
  respirationRhythm,

  severityLevels,
  sleepChecks,
  states,
  temperatureUnits,
  urineColors,
  urineLogTypes,
  urineMeasurements,
  weightUnits
} as const;

export type Lookups = typeof lookups;

type LookupsById = {
  [K in keyof Lookups]: { [id: string]: Lookups[K][number] };
};

export const lookupsById = mapValues(lookups, (lookup) =>
  keyBy(lookup, 'id')
) as LookupsById;
