export const omittedMedicationReasons = [
  {
    id: 'refused',
    name: 'Refused'
  },
  {
    id: 'missed',
    name: 'Missed'
  },
  {
    id: 'held',
    name: 'Withheld'
  },
  {
    id: 'contaminated',
    name: 'Contaminated'
  },
  {
    id: 'discontinued',
    name: 'Discontinued'
  },
  {
    id: 'discrepancy',
    name: 'Count Discrepancy'
  },
  {
    id: 'out',
    name: 'Out of Facility'
  },
  {
    id: 'homeVisit',
    name: 'Home Visit'
  },
  {
    id: 'hospital',
    name: 'At Hospital'
  },
  {
    id: 'undelivered',
    name: 'Prescription Undelivered'
  },
  {
    id: 'other',
    name: 'Other'
  }
] as const;

export const omittedMedicationReasonIds = omittedMedicationReasons.map(
  ({ id }) => id
);

export type OmittedMedicationReasonId =
  (typeof omittedMedicationReasonIds)[number];
