import { ConditionOption, pickOperators } from '../utils';
import { bmSizes } from './bm-sizes.lookup';
import { bmTextures } from './bm-textures.lookup';
import { mealTypes } from './meal-types.lookup';
import { moods } from './moods.lookup';

export type TLogDetail = {
  id: string;
  name: string;
  service: string;
  table: string;
  eager: Record<string, any>;
  icon: string;
  color: string;
  baseDisplayLabel: string;
  displayLabel: string;
  getResidentUrl: (residentId: string) => string;
  getDashboardUrl: (residentId: string) => string;
  dependencyDescription: string;
  isMedicationTaskDependency: boolean;
  comparisonFields?: ConditionOption[];
};

export const logDetails = [
  {
    id: 'activity',
    name: 'Activity',
    service: 'activity-logs',
    table: 'activityLogs',
    eager: {
      activityLog: {
        activity: true
      }
    },
    icon: '/assets/icon/material/cards_playing.svg',
    color: 'activity-logs',
    baseDisplayLabel: 'Activity / Outing',
    displayLabel: 'Activity Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/activity-outings`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/activity-outings/${residentId}`,
    dependencyDescription:
      'Requires a user to record an activity log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'behavior',
    name: 'Behavior',
    service: 'behavior-logs',
    table: 'behaviorLogs',
    eager: {
      behaviorLog: {
        behavior: true
      }
    },
    icon: '/assets/icon/material/theater_comedy.svg',
    color: 'behavior-logs',
    baseDisplayLabel: 'Behavior',
    displayLabel: 'Behavior Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/behaviors`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/behavior/${residentId}`,
    dependencyDescription:
      'Requires a user to record a behavior log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'bp',
    name: 'Blood Pressure',
    service: 'blood-pressure-logs',
    table: 'bloodPressureLogs',
    eager: {
      bloodPressureLog: true
    },
    icon: '/assets/icon/material/pulse.svg',
    color: 'blood-pressure-logs',
    baseDisplayLabel: 'Blood Pressure',
    displayLabel: 'Blood Pressure Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/blood-pressure`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/blood-pressure/${residentId}`,
    dependencyDescription:
      'Requires a user to record a blood pressure log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'systolic',
        label: 'Systolic',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      },
      {
        field: 'diastolic',
        label: 'Diastolic',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      },
      {
        field: 'pulse',
        label: 'Pulse',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      }
    ]
  },
  {
    id: 'bs',
    name: 'Blood Sugar',
    service: 'blood-sugar-logs',
    table: 'bloodSugarLogs',
    eager: {
      bloodSugarLog: true
    },
    icon: '/assets/icon/material/diabetes.svg',
    color: 'blood-sugar-logs',
    baseDisplayLabel: 'Blood Sugar',
    displayLabel: 'Blood Sugar Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/blood-sugar`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/blood-sugar/${residentId}`,
    dependencyDescription:
      'Requires a user to record a blood sugar log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'level',
        label: 'Level',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      }
    ]
  },
  {
    id: 'bodyCheck',
    name: 'Body Check',
    service: 'body-check-logs',
    table: 'bodyCheckLogs',
    eager: {
      bodyCheckLog: {
        observations: true
      }
    },
    icon: '/assets/icon/material/human_handsdown.svg',
    color: 'body-check-logs',
    baseDisplayLabel: 'Body Check',
    displayLabel: 'Body Check Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/body-checks`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/body-check/${residentId}`,
    dependencyDescription:
      'Requires a user to record a body check log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'bm',
    name: 'Bowel Movement',
    service: 'bm-logs',
    table: 'bmLogs',
    eager: {
      bmLog: true
    },
    icon: '/assets/icon/material/poop.svg',
    color: 'bm-logs',
    baseDisplayLabel: 'Bowel Movement',
    displayLabel: 'Bowel Movement Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/bowel-movements`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/bowel-movements/${residentId}`,
    dependencyDescription:
      'Requires a user to record a bowel movement log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'bmSizeId',
        label: 'Size',
        operators: pickOperators('=', '!='),
        valueType: 'select',
        options: bmSizes
      },
      {
        field: 'bmTextureId',
        label: 'Texture',
        operators: pickOperators('=', '!='),
        valueType: 'select',
        options: bmTextures
      }
    ]
  },
  {
    id: 'food',
    name: 'Food',
    service: 'food-logs',
    table: 'foodLogs',
    eager: {
      foodLog: true
    },
    icon: '/assets/icon/material/silverware.svg',
    color: 'food-logs',
    baseDisplayLabel: 'Food',
    displayLabel: 'Food Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/food`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/food/${residentId}`,
    dependencyDescription:
      'Requires a user to record a food log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'mealTypeId',
        label: 'Meal Type',
        operators: pickOperators('=', '!='),
        valueType: 'select',
        options: mealTypes
      },
      {
        field: 'amount',
        label: 'Amount',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      }
    ]
  },
  {
    id: 'mood',
    name: 'Mood',
    service: 'mood-logs',
    table: 'moodLogs',
    eager: {
      moodLog: true
    },
    icon: '/assets/icon/material/emoticon.svg',
    color: 'mood-logs',
    baseDisplayLabel: 'Mood',
    displayLabel: 'Mood Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/mood`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/mood/${residentId}`,
    dependencyDescription:
      'Requires a user to record a mood log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'mood',
        label: 'Mood',
        operators: pickOperators('=', '!='),
        valueType: 'select',
        options: moods
      }
    ]
  },
  {
    id: 'oxygen',
    name: 'Oxygen',
    service: 'oxygen-logs',
    table: 'oxygenLogs',
    eager: {
      oxygenLog: true
    },
    icon: '/assets/icon/alcomy/oxygen.svg',
    color: 'oxygen-logs',
    baseDisplayLabel: 'Oxygen',
    displayLabel: 'Oxygen Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/oxygen`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/oxygen/${residentId}`,
    dependencyDescription:
      'Requires a user to record an oxygen log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'position',
    name: 'Position',
    service: 'position-logs',
    table: 'positionLogs',
    eager: {
      positionLog: true
    },
    icon: '/assets/icon/material/airline_seat_flat.svg',
    color: 'position-logs',
    baseDisplayLabel: 'Position',
    displayLabel: 'Position Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/position`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/position/${residentId}`,
    dependencyDescription:
      'Requires a user to record a position log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'respiration',
    name: 'Respiration',
    service: 'respiration-logs',
    table: 'respirationLogs',
    eager: {
      respirationLog: true
    },
    icon: '/assets/icon/material/lungs.svg',
    color: 'respiration-logs',
    baseDisplayLabel: 'Respiration',
    displayLabel: 'Respiration Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/respiration`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/respiration/${residentId}`,
    dependencyDescription:
      'Requires a user to record a respiration log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'seizure',
    name: 'Seizure',
    service: 'seizure-logs',
    table: 'seizureLogs',
    eager: {
      seizureLog: {
        symptoms: {
          symptom: true
        }
      }
    },
    icon: '/assets/icon/alcomy/seizure.svg',
    color: 'seizure-logs',
    baseDisplayLabel: 'Seizure',
    displayLabel: 'Seizure Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/seizure`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/seizure/${residentId}`,
    dependencyDescription:
      'Requires a user to record a seizure log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'shower',
    name: 'Shower',
    service: 'shower-logs',
    table: 'showerLogs',
    eager: {
      showerLog: true
    },
    icon: '/assets/icon/material/bathtub.svg',
    color: 'shower-logs',
    baseDisplayLabel: 'Shower',
    displayLabel: 'Shower Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/shower`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/shower/${residentId}`,
    dependencyDescription: 'Requires a user to record a shower log',
    isMedicationTaskDependency: false
  },
  {
    id: 'sleep',
    name: 'Sleep',
    service: 'sleep-logs',
    table: 'sleepLogs',
    eager: {
      sleepLog: true
    },
    icon: '/assets/icon/material/sleep.svg',
    color: 'sleep-logs',
    baseDisplayLabel: 'Sleep',
    displayLabel: 'Sleep Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/sleep`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/sleep/${residentId}`,
    dependencyDescription:
      'Requires a user to record a sleep log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'note',
    name: 'Shift Note',
    service: 'shift-notes',
    table: 'shiftNotes',
    eager: {
      shiftNote: true
    },
    icon: '/assets/icon/material/account_balance_wallet.svg',
    color: 'shift-notes',
    baseDisplayLabel: 'Shift Note',
    displayLabel: 'Shift Notes',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/notes`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/notes/${residentId}`,
    dependencyDescription:
      'Requires a user to record a shift note to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'temp',
    name: 'Temperature',
    service: 'temperature-logs',
    table: 'temperatureLogs',
    eager: {
      temperatureLog: true
    },
    icon: '/assets/icon/material/thermometer.svg',
    color: 'temperature-logs',
    baseDisplayLabel: 'Temperature',
    displayLabel: 'Temperature Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/temperature`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/temperature/${residentId}`,
    dependencyDescription:
      'Requires a user to record a temperature log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'temperature',
        label: 'Temperature',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      }
    ]
  },
  {
    id: 'urine',
    name: 'Urine',
    service: 'urine-logs',
    table: 'urineLogs',
    eager: {
      urineLog: true
    },
    icon: '/assets/icon/alcomy/catheter.svg',
    color: 'urine-logs',
    baseDisplayLabel: 'Urine',
    displayLabel: 'Urine Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/urine`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/urine/${residentId}`,
    dependencyDescription:
      'Requires a user to record a urine log to determine the dose to give.',
    isMedicationTaskDependency: false
  },
  {
    id: 'vital',
    name: 'Vital',
    service: 'vital-logs',
    table: 'vitalLogs',
    eager: {
      vitalLog: true
    },
    icon: '/assets/icon/material/vital.svg',
    color: 'vital-logs',
    baseDisplayLabel: 'Vital',
    displayLabel: 'Vital Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/vital`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/vital/${residentId}`,
    dependencyDescription:
      'Requires a user to record a vital log to determine the dose to give.',
    isMedicationTaskDependency: true
  },
  {
    id: 'water',
    name: 'Water',
    service: 'water-logs',
    table: 'waterLogs',
    eager: {
      waterLog: true
    },
    icon: '/assets/icon/material/water.svg',
    color: 'water-logs',
    baseDisplayLabel: 'Water',
    displayLabel: 'Water Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/water`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/water/${residentId}`,
    dependencyDescription:
      'Requires a user to record a water log to determine the dose to give.',
    isMedicationTaskDependency: false,
    comparisonFields: [
      {
        field: 'amount',
        label: 'Amount',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      }
    ]
  },
  {
    id: 'weight',
    name: 'Weight',
    service: 'weight-logs',
    table: 'weightLogs',
    eager: {
      weightLog: true
    },
    icon: '/assets/icon/material/scale.svg',
    color: 'weight-logs',
    baseDisplayLabel: 'Weight',
    displayLabel: 'Weight Logs',
    getResidentUrl: (residentId: string) =>
      `/residents/${residentId}/notebook/weight`,
    getDashboardUrl: (residentId: string) =>
      `/dashboard/notebook/weight/${residentId}`,
    dependencyDescription:
      'Requires a user to record a weight log to determine the dose to give.',
    isMedicationTaskDependency: true,
    comparisonFields: [
      {
        field: 'weight',
        label: 'Weight',
        operators: pickOperators('<', '<=', '=', '!=', '>=', '>'),
        valueType: 'number'
      }
    ]
  }
] as const satisfies TLogDetail[];

export const logTypeIds = logDetails.map(({ id }) => id);

export type LogDetail = (typeof logDetails)[number];

export type LogTypeId = LogDetail['id'];

export type LogNames = LogDetail['name'];
