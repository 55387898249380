export const medicationTypes = [
  {
    id: 'antacid',
    name: 'Antacid',
    description:
      'Antacids are used to neutralize stomach acid and relieve heartburn and indigestion.'
  },
  {
    id: 'antiallergic',
    name: 'Antiallergic',
    description: 'Antiallergics are used to treat allergies and hay fever.'
  },
  {
    id: 'antiarrhythmic',
    name: 'Antiarrhythmic',
    description: 'Antiarrhythmics are used to treat irregular heart rhythms.'
  },
  {
    id: 'antihyperlipidemic',
    name: 'Antihyperlipidemic',
    description:
      'Antihyperlipidemics are used to lower cholesterol and triglycerides. These consist of statins, fibrates, Ezetimibe, and PCSK9 inhibitors.'
  },
  {
    id: 'antiinflammatory',
    name: 'Anti-inflammatory',
    description: 'Anti-inflammatories are used to reduce inflammation and pain.'
  },
  {
    id: 'antibiotic',
    name: 'Antibiotic',
    description: 'Antibiotics are used to treat bacterial infections.'
  },
  {
    id: 'antidepressant',
    name: 'Antidepressant',
    description: 'Antidepressants are used to treat depression.'
  },
  {
    id: 'antidiabetic',
    name: 'Antidiabetic',
    description: 'Antidiabetics are used to treat diabetes.'
  },
  {
    id: 'antidiarrhoeal', // british spelling
    name: 'Antidiarrheal',
    description: 'Antidiarrheals are used to treat diarrhea.'
  },
  {
    id: 'antiemetic',
    name: 'Antiemetic',
    description: 'Antiemetics are used to prevent nausea and vomiting.'
  },
  {
    id: 'antiepileptic',
    name: 'Antiepileptic',
    description: 'Antiepileptics are used to treat epilepsy.'
  },
  {
    id: 'antifungal',
    name: 'Antifungal',
    description: 'Antifungals are used to treat fungal infections.'
  },
  {
    id: 'antihistamine',
    name: 'Antihistamine',
    description: 'Antihistamines are used to treat allergies and hay fever.'
  },
  {
    id: 'antihypertensive',
    name: 'Antihypertensive',
    description: 'Antihypertensives are used to treat high blood pressure.'
  },
  {
    id: 'antiparkinson',
    name: 'Antiparkinson',
    description:
      "Antiparkinson medications are used to treat Parkinson's disease."
  },
  {
    id: 'antipsychotic',
    name: 'Antipsychotic',
    description:
      'Antipsychotics are used to treat schizophrenia and other mental illnesses.'
  },
  {
    id: 'anxiolytic',
    name: 'Anxiolytic (Anti-anxiety)',
    description: 'Anxiolytics are used to treat anxiety.'
  },
  {
    id: 'birthcontrol',
    name: 'Birth-control',
    description: 'Birth control medications are used to prevent pregnancy.'
  },
  {
    id: 'bloodpressure',
    name: 'Blood-pressure',
    description:
      'Blood pressure medications are used to treat high blood pressure.'
  },
  {
    id: 'bloodthinner',
    name: 'Blood Thinner',
    description: 'Blood thinners are used to prevent blood clots.'
  },
  {
    id: 'diuretic',
    name: 'Diuretic',
    description:
      'Diuretics are used to treat fluid retention and kidney disease.'
  },
  {
    id: 'decongestant',
    name: 'Decongestant',
    description: 'Decongestants are used to treat nasal or chest congestion.'
  },
  {
    id: 'hypnotic',
    name: 'Hypnotic',
    description: 'Hypnotics are used to treat insomnia.'
  },
  {
    id: 'laxative',
    name: 'Laxative',
    description: 'Laxatives are used to treat constipation.'
  },
  {
    id: 'memory',
    name: 'Memory',
    description:
      'Memory medications are used to improve memory and cognitive function.'
  },
  {
    id: 'multivitamin',
    name: 'Multivitamin',
    description:
      'Multivitamins are used to supplement the diet with essential vitamins and minerals.'
  },
  {
    id: 'muscle-relaxant',
    name: 'Muscle relaxant',
    description: 'Muscle relaxants are used to treat muscle spasms and pain.'
  },
  {
    id: 'other',
    name: 'Other',
    description:
      'Other encompasses all medications that do not fit into the other types.'
  },
  {
    id: 'painkiller',
    name: 'Painkiller',
    description: 'Painkillers are used to treat pain.'
  },
  {
    id: 'probiotic',
    name: 'Probiotic',
    description: 'Probiotics are used to promote a healthy gut microbiome.'
  },
  {
    id: 'sedative',
    name: 'Sedative',
    description: 'Sedatives are used to induce sleep and reduce anxiety.'
  },
  {
    id: 'stool-softener',
    name: 'Stool Softener',
    description: 'Stool softeners are used to treat constipation.'
  },
  {
    id: 'vitamin',
    name: 'Vitamin',
    description:
      'Vitamins are used to supplement the diet with essential vitamins and minerals.'
  }
] as const;

export const medicationTypeIds = medicationTypes.map(({ id }) => id);

export type MedicationTypeId = (typeof medicationTypeIds)[number];
