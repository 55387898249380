import { keyBy, mapValues } from 'lodash';
import { chainFlow } from '../utils';

export const featureFlags = [
  {
    id: 'documents',
    name: 'Documents',
    description:
      'Enable the documents feature. This must be turned on for all other document flags to take effect.',
    defaultValue: true
  },
  {
    id: 'documents-facility',
    name: 'Documents - Facility',
    description: 'Enable the documents feature for the facility.',
    defaultValue: false
  },
  {
    id: 'documents-staff',
    name: 'Documents - Staff',
    description:
      'Enable staff documents to allow facilities the ability to track and manage documents for facility users.',
    defaultValue: true
  },
  {
    id: 'documents-resident',
    name: 'Documents - Resident',
    description:
      'Enable resident documents to allow facilities the ability to track and manage documents for residents.',
    defaultValue: true
  },
  {
    id: 'documents-incomplete',
    name: 'Documents - Incomplete',
    description:
      'Enable document features that are incomplete. e.g. Some unused tabs in the community library modal, and the "files" tab in the documents module for google drive-like functionality.',
    defaultValue: false
  },
  {
    id: 'documents-forms',
    name: 'Documents - Forms',
    description:
      'Enable digital forms for documents to allow facilities the ability to add participants and view/interact with the form tab in the document detail page.',
    defaultValue: true
  },
  {
    id: 'incident-reports',
    name: 'Incident Reports',
    description: 'Enable the resident incident reports.',
    defaultValue: false
  },
  {
    id: 'experiments',
    name: 'Experiments',
    description: 'Enable the experiments feature in the main menu.',
    defaultValue: false
  },
  {
    id: 'move-resident',
    name: 'Move Resident',
    description: 'Enable the ability to move residents between facilities.',
    defaultValue: false
  },
  {
    id: 'medication-destruction',
    name: 'Medication Destruction',
    description: 'Enable the medication destruction feature.',
    defaultValue: true
  },
  {
    id: 'narcotics-count',
    name: 'Narcotics Count',
    description: 'Enable the narcotics count feature.',
    defaultValue: true
  },
  {
    id: 'group-by-care-task-list',
    name: 'Group by Care Task List',
    description:
      'Enable the group by feature for care tasks. This allows users to choose group by time or group by resident.',
    defaultValue: true
  },
  {
    id: 'pharmacy-integration-medication-review',
    name: 'Pharmacy Integration - Medication Review',
    description:
      'Enable the pharmacy integration feature and access to the medication review page on the dashboard.',
    defaultValue: true
  },
  {
    id: 'resident-reports',
    name: 'Resident Reports',
    description: 'Enable the ability to create custom reports for resident.',
    defaultValue: true
  },
  {
    id: 'ai-summarize-notes',
    name: 'AI Summary Notes',
    description: 'Enable the AI summary notes feature. Still experimental.',
    defaultValue: false
  }
] as const;

export const featureFlagIds = featureFlags.map((flag) => flag.id);

export type FeatureFlagId = (typeof featureFlagIds)[number];

export const getDefaultFeatureFlags = (): Record<FeatureFlagId, boolean> =>
  chainFlow(
    featureFlags,
    (flags) => keyBy(flags, 'id'),
    (flagsById) => mapValues(flagsById, 'defaultValue')
  );
